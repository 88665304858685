import React, { useState, useEffect } from "react";
// import { Link, withRouter } from "react-router-dom";
import { Link } from "@gatsbyjs/reach-router";
import Web3 from "web3";
import { cubes } from "../../services/constants";
import config, { currencyFormatter } from "../../config/config";
import dateFormat from "dateformat";
import { withTranslation } from "react-i18next";
import ApiService from "../../services/api_services";
import helper from "../../services/helper";

import "../../assets/hypdex.css";
import "../../assets/hypdex-responsive.css";
import "../../assets/select.css";
import { jQueryFunc, loadPage } from "../../assets/js/hypdex.js";
import $ from "jquery";
//import "datatables.net";
import { hotjar } from "react-hotjar";
import useWalletAddress from "../../assets/hooks/useWalletAddress";
import { useTranslation } from "react-i18next";

function Account(props) {
  const [myAssetList, setMyAssetList] = useState([]);
  const [ledgerLoad, setLedgerLoad] = useState(true);
  const [myAssetsLoad, setMyAssetsLoad] = useState(true);
  const [myAssetTrx, setMyAssetTrx] = useState([]);
  const [usdInvestment, setUsdInvestment] = useState("0.00");
  const [usdProfit, setUsdProfit] = useState("0.00");
  const [hyperTokenProfit, setHyperTokenProfit] = useState("0.00");
  const [balanceOf, setBalanceOf] = useState("0.00");
  const [loading, setLoading] = useState(true);
  const [selectedCube, setSelectedCube] = useState(-1);
  const [table, setTable] = useState(null);
  const [activeCubesByUser, setActiveCubesByUser] = useState([]);
  const [updatesCount, setUpdatesCount] = useState(0);
  const { walletAddress, setWalletAddress } = useWalletAddress();
  // const { walletAddress } = props;
  //const { t, location, walletAddress, setWalletAddress } = props;
  const { location } = props;
  const { t } = useTranslation();
  useEffect(() => {
    jQueryFunc();
    //GA feature
    //loadPage(location.pathname + location.search);
    if (window.ethereum) {
      // let web3 = new Web3(window.ethereum);
      // var account = web3.currentProvider.selectedAddress;
      // if (!account) {
      // } else {
      //   localStorage.setItem("walletAddress", account);
      //   setWalletAddress(account);

      getContractDetail();
      const interval = setInterval(() => {
        getContractDetail();
      }, 60000);
      return () => clearInterval(interval);
    }
    // } else {
    //   // setLoading(false);
    // }
  }, [location, setWalletAddress]);

  useEffect(() => {
    $("#options-view-button").prop("checked", false);
  }, [selectedCube]);

  useEffect(() => {
    if (updatesCount !== 0) {
      getMyAssetsList();
    }
  }, [updatesCount]);

  useEffect(() => {
    //getLedgerList();
    getActiveCubesByUser();
  }, [walletAddress]);

  const getContractDetail = async () => {
    if (window.ethereum) {
      let web3 = await new Web3(window.ethereum);
      var account = await web3.currentProvider.selectedAddress;
      var HyperTokenContract = await new web3.eth.Contract(
        config.HyperTokenABI,
        config.HyperTokenContract
      );
      var BalanceToken = await HyperTokenContract.methods
        .balanceOf(account)
        .call();
      var hyperTokenDecimals = await HyperTokenContract.methods
        .decimals()
        .call();
      setBalanceOf(BalanceToken / 10 ** hyperTokenDecimals);
      var totalUsdInvestment = 0;
      var totalUsdProfit = 0;
      var totalHyperTokenProfit = 0;
      for (var fc = 0; fc < config.FundManagerContracts.length; fc++) {
        var fmc = config.FundManagerContracts[fc];
        if (fmc !== "") {
          var FundManagerContract = await new web3.eth.Contract(
            config.FundManagerABIs[fc],
            fmc
          );
          var getTotalUserActualProfit = await FundManagerContract.methods
            .getTotalUserActualProfit(account)
            .call();
          totalUsdInvestment +=
            parseInt(
              getTotalUserActualProfit.usdInvestment ||
                getTotalUserActualProfit.localUsdInvestment
            ) / 1e18;
          totalUsdProfit +=
            parseInt(
              getTotalUserActualProfit.usdProfit ||
                getTotalUserActualProfit.localUsdProfit
            ) / 1e18;
          totalHyperTokenProfit +=
            (parseInt(
              getTotalUserActualProfit.hyperTokenProfitBase ||
                getTotalUserActualProfit.localHyperTokenProfitBase
            ) +
              parseInt(
                getTotalUserActualProfit.hyperTokenProfitBonus ||
                  getTotalUserActualProfit.localHyperTokenProfitBonus
              )) /
            10 ** hyperTokenDecimals;
        }
      }
      setHyperTokenProfit(totalHyperTokenProfit);
      setUsdProfit(totalUsdProfit.toFixed(12));
      setUsdInvestment(totalUsdInvestment.toFixed(12));
    }
    setLoading(false);
  };

  const tradingPath = (cubeType) => {
    var tradingPath = "";
    if (cubeType === 0) {
      tradingPath = "algo";
    } else if (cubeType === 1) {
      tradingPath = "fix";
    } else if (cubeType === 2) {
      tradingPath = "race";
    }
    return tradingPath;
  };

  const getActiveCubesByUser = async () => {
    const url = "https://admin.hyperdex.cloud/graphql"; // `https://test.hyperdex.cloud/web/cube/${cubeType}/${id}/investorslist`;
    const apiService = new ApiService();
    const query =
      '{ ActiveCubesByUser(userAddress: "' +
      walletAddress +
      '") {currentInvestment,cubeType,cubeID} }';
    apiService
      .fetchGraphQL(url, {
        method: "POST",
        body: JSON.stringify({ query: query }),
      })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.ActiveCubesByUser &&
          helper.isArray(response.data.ActiveCubesByUser)
        ) {
          setActiveCubesByUser(response.data.ActiveCubesByUser);
          setUpdatesCount(updatesCount + 1);
        }
      })
      .catch((error) => {
        console.log("error getActiveCubesByUser", error);
        setActiveCubesByUser([]);
        setUpdatesCount(updatesCount + 1);
      });
  };

  const getLedgerList = async () => {
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      var account = await web3.currentProvider.selectedAddress;
      var HyperTokenContract = new web3.eth.Contract(
        config.HyperTokenABI,
        config.HyperTokenContract
      );

      var myArray = cubes;
      let ledgerList = [];
      var count = 0;

      for (var i = 0; i < myArray.length; i++) {
        let cubeType = myArray[i].cubeType;
        let abi = config.FundManagerABIs[cubeType];
        var FundManagerContract = new web3.eth.Contract(
          abi,
          config.FundManagerContracts[cubeType]
        );
        var cubeID = myArray[i].id;
        var cubeInfo = await FundManagerContract.methods
          .cubeInfo(cubeID)
          .call();
        var cubeTokenAddress = cubeInfo.asset;
        let abiCube = config.HyperTokenABI;
        var cubeTokenContract = new web3.eth.Contract(
          abiCube,
          cubeTokenAddress
        );
        var decimals = await cubeTokenContract.methods.decimals().call();
        var hyperTokenDecimals = await HyperTokenContract.methods
          .decimals()
          .call();

        var getLedgerEntries = await FundManagerContract.methods
          .getLedgerEntries(cubeID, account, 25, 0)
          .call();
        var partialLedgerList = [];
        for (var l = 0; l < parseInt(getLedgerEntries.thisListCount); l++) {
          var newEntry = Object.assign(
            {},
            getLedgerEntries.ledgerEntriesList[l]
          );
          newEntry.id = count++;
          newEntry.description = myArray[i].description;
          newEntry.decimals = decimals;
          newEntry.assetDecimalsToShow = myArray[i].assetDecimalsToShow;
          newEntry.hyperTokenDecimals = hyperTokenDecimals;
          newEntry.symbol = cubeInfo.symbol;
          newEntry.time = new Date(parseInt(newEntry.timestamp) * 1000);
          newEntry.cubeType = cubeType;
          newEntry.tradingPath = tradingPath(cubeType);
          newEntry.cubeID = cubeID;
          newEntry.cubeINDEX = myArray[i].index;
          newEntry.totalProfitLossAsset =
            (parseInt(getLedgerEntries.ledgerEntriesList[l].profitAmount || 0) +
              parseInt(getLedgerEntries.ledgerEntriesList[l].lossAmount || 0)) /
            10 ** decimals;
          newEntry.totalProfitLossHyp =
            (parseInt(
              getLedgerEntries.ledgerEntriesList[l].hyperTokenProfitBase || 0
            ) +
              parseInt(
                getLedgerEntries.ledgerEntriesList[l].hyperTokenProfitBonus || 0
              )) /
            10 ** hyperTokenDecimals;
          partialLedgerList.push(newEntry);
        }
        setFixedPL(partialLedgerList);
        ledgerList.push(...partialLedgerList);
      }
      ledgerList.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
      setMyAssetTrx(ledgerList);

      if (table) {
        table.destroy();
      }
      let localTable = $("#transactions").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.24/i18n/English.json",
        },
      });
      setTable(localTable);
    } else {
      console.log("window ether not defined");
    }
    setLedgerLoad(false);
  };

  const setFixedPL = (ledgerList) => {
    var progressUnlock = 0;
    ledgerList.sort((a, b) => {
      return a.timestamp - b.timestamp;
    });
    for (var l = 0; l < ledgerList.length; l++) {
      if (ledgerList[l].cubeType === 1) {
        let tempValue =
          parseInt(ledgerList[l].assetAmount || 0) /
          10 ** ledgerList[l].decimals;
        let opType = +ledgerList[l].operationType;
        if (opType === 3 || opType === 5) {
          progressUnlock += tempValue;
        } else if (opType === 2) {
          ledgerList[l].totalProfitLossAsset = tempValue - progressUnlock;
          progressUnlock = 0;
        }
      }
    }
  };

  const getMyAssetsList = async () => {
    if (window.ethereum) {
      let web3 = await new Web3(window.ethereum);
      var account = await web3.currentProvider.selectedAddress;
      var HyperTokenContract = await new web3.eth.Contract(
        config.HyperTokenABI,
        config.HyperTokenContract
      );

      var myArray = await cubes;
      let newArray = [];
      var cubeCount = 1;

      for (var i = 0; i < myArray.length; i++) {
        let cubeType = myArray[i].cubeType;
        let abi = await config.FundManagerABIs[cubeType];
        var FundManagerContract = await new web3.eth.Contract(
          abi,
          config.FundManagerContracts[cubeType]
        );
        var cubeID = myArray[i].id;
        if (activeCubesByUser) {
          const activeCube = activeCubesByUser.find((item) => {
            return item.cubeType === cubeType && item.cubeID === cubeID;
          });
          if (activeCube) {
            var userInfo = await FundManagerContract.methods
              .userInfo(cubeID, account)
              .call();
            var getActualProfit = await FundManagerContract.methods
              .getActualProfit(cubeID, account)
              .call();
            var cubeInfo = await FundManagerContract.methods
              .cubeInfo(cubeID)
              .call();

            var cubeTokenAddress = cubeInfo.asset;
            let abiCube = await config.HyperTokenABI;
            var cubeTokenContract = await new web3.eth.Contract(
              abiCube,
              cubeTokenAddress
            );

            var decimals = await cubeTokenContract.methods.decimals().call();
            var hyperTokenDecimals = await HyperTokenContract.methods
              .decimals()
              .call();

            var InvestedAmount =
              parseInt(userInfo.assetAmount) +
              (userInfo.suspendedAmount
                ? parseInt(userInfo.suspendedAmount)
                : 0);
            InvestedAmount = InvestedAmount / 10 ** decimals;
            var ProfitInAsset =
              (parseInt(getActualProfit.profitDetail.assetProfit) -
                parseInt(getActualProfit.profitDetail.assetLoss || 0)) /
              10 ** decimals;
            let assetPriceUSD = parseInt(getActualProfit.assetPriceUSD) / 1e18;

            var UnlockedAssetAmount = parseInt(
              userInfo.earlyUnlockedValues.assetAmount || 0
            );
            if (userInfo.unlockedValues) {
              UnlockedAssetAmount += parseInt(
                userInfo.unlockedValues.assetAmount || 0
              );
            }
            UnlockedAssetAmount /= 10 ** decimals;

            if (InvestedAmount + UnlockedAssetAmount > 0) {
              myArray[i].InvestedAmount = InvestedAmount;
              myArray[i].ProfitInAsset = ProfitInAsset;
              myArray[i].ProfitInAssetUSD = ProfitInAsset * assetPriceUSD;
              myArray[i].EquityInAsset = InvestedAmount + ProfitInAsset;
              myArray[i].Expiration = new Date(
                parseInt(cubeInfo.times.expiration) * 1000
              );
              myArray[i].InvestedHyperTokenAmount =
                parseInt(userInfo.hyperTokenAmount) / 10 ** hyperTokenDecimals;
              myArray[i].ProfitInHyperToken =
                (parseInt(getActualProfit.profitDetail.hyperTokenProfitBase) +
                  parseInt(
                    getActualProfit.profitDetail.hyperTokenProfitBonus
                  )) /
                10 ** hyperTokenDecimals;
              myArray[i].UnlockedAssetAmount = UnlockedAssetAmount;
              myArray[i].tradingPath = tradingPath(cubeType);
              myArray[i].top = cubeCount * 420 + "px";
              let n = (cubeCount % 2) * 300 - 400;
              myArray[i].left =
                "calc(50% " + (n < 0 ? "- " : "+ ") + Math.abs(n) + "px)";
              newArray.push(myArray[i]);
              cubeCount++;
            }
          }
        }
      }
      setMyAssetList(newArray);
    } else {
      console.log("window ether not defined");
    }
    setMyAssetsLoad(false);
  };

  useEffect(() => {
    jQueryFunc();
    hotjar.initialize("3117947", 6);
  }, [ledgerLoad]);

  const operationTypeColor = (opType) => {
    if (opType === 1) {
      return "#ffa0f8";
    } else if (opType === 2) {
      return "limegreen";
    } else if (opType === 3) {
      return "orange";
    } else if (opType === 4) {
      return "orangered";
    } else if (opType === 5) {
      return "#b0ffa0";
    } else {
      return "gray";
    }
  };

  const typeString = (opType) => {
    if (opType === 1) {
      return "Invest";
    } else if (opType === 2) {
      return "Withdraw";
    } else if (opType === 3) {
      return "Unlock";
    } else if (opType === 4) {
      return "Early Unlock";
    } else if (opType === 5) {
      return "Activate";
    } else {
      return "Operation Type " + opType.toString();
    }
  };

  const cubeTypeString = (c) => {
    if (c === 0) {
      return "Algo Cube";
    } else if (c === 1) {
      return "Fixed Cube";
    } else if (c === 2) {
      return "Race Cube";
    } else {
      return "Type" + c + " Cube";
    }
  };

  const totalValue = (item) => {
    let a =
      (((parseInt(item.assetAmount) +
        parseInt(item.profitAmount) -
        parseInt(item.lossAmount || 0)) /
        10 ** item.decimals) *
        parseInt(item.assetPrice)) /
      1e18;
    let b =
      (((parseInt(item.hyperTokenAmount) +
        parseInt(item.hyperTokenProfitBase) +
        parseInt(item.hyperTokenProfitBonus)) /
        10 ** item.hyperTokenDecimals) *
        parseInt(item.hyperTokenPrice)) /
      1e18;
    return a + b;
  };

  const disconnectWallet = async (e) => {
    if (window.ethereum && walletAddress) {
      localStorage.setItem("walletAddress", "");
      setWalletAddress("");
      window.location.href = "/";
    }
  };

  return (
    <>
      <section className="page-header p-b-0">
        <div className="page-header-bg"></div>

        <div className="page-header-shape-1"></div>
        <div className="page-header-shape-2"></div>
        <div className="page-header-shape-3"></div>

        <div className="container text-center">
          <div className="page-header__inner">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="wrapper">
                  <div className="wrapper__content">
                    <div className="cube_dot">
                      <div className="cube__side cube__side--front"></div>
                      <div className="cube__side cube__side--back"></div>
                      <div className="cube__side cube__side--left"></div>
                      <div className="cube__side cube__side--right"></div>
                      <div className="cube__side cube__side--top"></div>
                      <div className="cube__side cube__side--bottom"></div>
                    </div>

                    <div className="shadowbox">
                      <div className="cube_dot">
                        <div className="cube__side cube__side--front"></div>
                        <div className="cube__side cube__side--back"></div>
                        <div className="cube__side cube__side--left"></div>
                        <div className="cube__side cube__side--right"></div>
                        <div className="cube__side cube__side--top"></div>
                        <div className="cube__side cube__side--bottom"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <h3 style={{ textAlign: "center", color: "white" }}>
                  {t("Wallet Connected")}
                </h3>
                <p>{walletAddress}</p>
                <Link
                  to="#"
                  style={{ background: "#a73296" }}
                  className="thm-btn comment-form__btn padding_select "
                  onClick={() => disconnectWallet()}
                >
                  {t("Disconnect")}
                </Link>
                <hr />
                <div className="row m-t-20">
                  <h3 style={{ textAlign: "center", color: "white" }}>
                    {t("Your Overall Stats")}
                  </h3>
                  {loading ? (
                    <h3 className="text-white text-center">
                      {t("Loading data...")}
                    </h3>
                  ) : (
                    <>
                      <div className="col-md-6 col-xs-12">
                        <h3
                          className="top m-t-20"
                          style={{ textAlign: "center", color: "white" }}
                        >
                          {t("Investment")}
                          <br />
                          <b style={{ color: "#ffa0f8" }}>
                            {parseFloat(usdInvestment).toFixed(2)} $
                          </b>{" "}
                        </h3>
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <h3
                          className="top m-t-20"
                          style={{ textAlign: "center", color: "white" }}
                        >
                          {t("Profit")} <br />
                          <b style={{ color: "#ffa0f8" }}>
                            {parseFloat(usdProfit).toFixed(2)} $
                          </b>{" "}
                        </h3>
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <h3
                          className="top m-t-20"
                          style={{ textAlign: "center", color: "white" }}
                        >
                          {t("Hypertoken Profit")}
                          <br />
                          <b style={{ color: "#ffa0f8" }}>
                            {parseFloat(hyperTokenProfit).toFixed(
                              config.HyperTokenDecimalsToShow
                            )}{" "}
                            HYP
                          </b>{" "}
                        </h3>
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <h3
                          className="top m-t-20"
                          style={{ textAlign: "center", color: "white" }}
                        >
                          {t("Hypertoken Balance")}
                          <br />
                          <b style={{ color: "#ffa0f8" }}>
                            {currencyFormatter(parseFloat(balanceOf), 2)} HYP
                          </b>{" "}
                        </h3>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <h3 style={{ textAlign: "center", color: "white" }}>
            {t("Your Investments")}
          </h3>
          {myAssetsLoad ? (
            <h3 className="text-white text-center">Loading data...</h3>
          ) : myAssetList.length === 0 || activeCubesByUser.length === 0 ? (
            <h3 className="text-white text-center">No Assets Available</h3>
          ) : (
            <>
              <form id="app-cover" style={{ marginBottom: "20px" }}>
                <div id="select-box">
                  <input type="checkbox" id="options-view-button" />
                  <div id="select-button" className="brd">
                    <div id="selected-value">
                      <span>Select a Cube</span>
                    </div>
                    <div id="chevrons">
                      <i className="fas fa-chevron-up"></i>
                      <i className="fas fa-chevron-down"></i>
                    </div>
                  </div>
                  <div id="options">
                    {myAssetList.map(
                      (item) =>
                        item.InvestedAmount + item.UnlockedAssetAmount > 0 && (
                          <div
                            className="option"
                            key={"invest_" + item.index}
                            onClick={() => setSelectedCube(item.index)}
                          >
                            <input
                              className="s-c top"
                              type="radio"
                              name="cube"
                              value={"invest_" + item.index}
                            />
                            <input
                              className="s-c bottom"
                              type="radio"
                              name="cube"
                              value={"invest_" + item.index}
                            />
                            <span className="label">
                              <div className="row">
                                <div className="col-md-4">
                                  {item.description}
                                </div>
                                <div className="col-md-4">
                                  <h5 style={{ color: "grey", float: "right" }}>
                                    {cubeTypeString(item.cubeType)}
                                  </h5>
                                </div>
                                <div className="col-md-4">
                                  <h5
                                    style={{ color: "#ffa0f8", float: "right" }}
                                  >
                                    {item.EquityInAsset.toFixed(
                                      item.assetDecimalsToShow
                                    )}{" "}
                                    {item.token?.symbol}
                                  </h5>
                                </div>
                              </div>
                            </span>
                            <span className="opt-val">{item.description}</span>
                          </div>
                        )
                    )}
                    <div id="option-bg"></div>
                  </div>
                </div>
              </form>

              <div className="tabs-content">
                {myAssetList.map(
                  (item) =>
                    item.InvestedAmount + item.UnlockedAssetAmount > 0 &&
                    item.index === selectedCube && (
                      <div
                        className="tab"
                        key={"invest_" + item.index}
                        id={"invest_" + item.index}
                      >
                        <div className="col-md-6 offset-md-3 bg-hex">
                          <div
                            className="row m-t-20 "
                            style={{ padding: "0 2em" }}
                          >
                            <h3 style={{ textAlign: "center", color: "white" }}>
                              {item.description || ""}
                            </h3>
                            <h5 style={{ textAlign: "center", color: "grey" }}>
                              {cubeTypeString(item.cubeType)}
                            </h5>
                            <div className="col-md-6 col-xs-12">
                              <h3
                                className="top m-t-20"
                                style={{ textAlign: "center", color: "white" }}
                              >
                                {t("Asset")}
                                <br />
                                <b style={{ color: "#ffa0f8" }}>
                                  {item.token?.symbol}
                                </b>{" "}
                              </h3>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h3
                                className="top m-t-20"
                                style={{ textAlign: "center", color: "white" }}
                              >
                                {t("Investment")}
                                <br />
                                <b style={{ color: "#ffa0f8" }}>
                                  {item.InvestedAmount.toFixed(
                                    item.assetDecimalsToShow
                                  )}{" "}
                                  {item.token?.symbol}
                                  <br />
                                  {item.InvestedHyperTokenAmount
                                    ? item.InvestedHyperTokenAmount.toFixed(
                                        config.HyperTokenDecimalsToShow
                                      )
                                    : "0.00"}{" "}
                                  HYP
                                </b>
                              </h3>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h3
                                className="top m-t-20"
                                style={{ textAlign: "center", color: "white" }}
                              >
                                {t("Asset Profit")} <br />
                                <b style={{ color: "#ffa0f8" }}>
                                  {item.ProfitInAsset.toFixed(
                                    item.assetDecimalsToShow
                                  )}{" "}
                                  {item.token?.symbol}
                                  <br />
                                  ($ {item.ProfitInAssetUSD.toFixed(2)})
                                </b>
                              </h3>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h3
                                className="top m-t-20"
                                style={{ textAlign: "center", color: "white" }}
                              >
                                {t("Hypertoken Profit")}
                                <br />
                                <b style={{ color: "#ffa0f8" }}>
                                  {item.ProfitInHyperToken.toFixed(
                                    config.HyperTokenDecimalsToShow
                                  )}{" "}
                                  HYP
                                </b>{" "}
                              </h3>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h3
                                className="top m-t-20"
                                style={{ textAlign: "center", color: "white" }}
                              >
                                {t("Asset Equity")}
                                <br />
                                <b style={{ color: "#ffa0f8" }}>
                                  {item.EquityInAsset.toFixed(
                                    item.assetDecimalsToShow
                                  )}
                                </b>{" "}
                              </h3>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h3
                                className="top m-t-20"
                                style={{ textAlign: "center", color: "white" }}
                              >
                                {t("Expiration")}
                                <br />
                                <b style={{ color: "#ffa0f8" }}>
                                  {dateFormat(item.Expiration, "mmm d, yyyy")}
                                </b>{" "}
                              </h3>
                            </div>
                            <div className="col-md-12 col-xs-12 text-center">
                              <Link
                                to={`/trading/${item.tradingPath}/${
                                  item.id || 0
                                }/${item.index || 0}/details`}
                                className="thm-btn comment-form__btn padding_select"
                                style={{
                                  color: "var(--hypdex-base)",
                                  backgroundColor: "var(--hypdex-white)",
                                }}
                              >
                                {t("Investment Details")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            </>
          )}
        </div>
      </section>

      <section>
        <div className="container">
          <hr />
          <div className="text-center">
            <h2 className="text-white"> {t("Transactions")}</h2>
          </div>
          <div className="table-responsive results" id="header-table">
            {ledgerLoad ? (
              <h3 className="text-white text-center">{t("Loading data...")}</h3>
            ) : (
              <table
                id="transactions"
                className="table account-datatable"
                width="100%"
                tabIndex="0"
                aria-label="results"
              >
                <thead>
                  <tr>
                    <th tabIndex="0" aria-label="">
                      {t("Date")}
                    </th>
                    <th tabIndex="0" aria-label="">
                      {t("Cube")}
                    </th>
                    <th data-type="0" tabIndex="0" aria-label="">
                      {t("Amount")}
                    </th>
                    <th tabIndex="0" aria-label="">
                      {t("Realized Profit")}
                    </th>
                    <th data-type="0">{t("Approx. Spot Prices")}</th>
                    <th data-type="0">{t("Approx. Value")}</th>
                    <th tabIndex="0" aria-label=""></th>
                  </tr>
                </thead>

                <tbody>
                  {myAssetTrx.map((item) => (
                    <tr key={item.id}>
                      <td
                        className="align-middle"
                        style={{ display: "table-cell" }}
                        tabIndex="0"
                        data-order={item.timestamp}
                        data-sort="desc"
                      >
                        {dateFormat(item.time, "mmm d, yyyy")}
                        <br />
                        <div
                          style={{
                            color: operationTypeColor(+item.operationType),
                          }}
                        >
                          {typeString(+item.operationType)}
                        </div>
                      </td>
                      <td
                        className="align-middle"
                        style={{ display: "table-cell" }}
                        tabIndex="0"
                      >
                        {item.description}
                        <br />
                        {cubeTypeString(item.cubeType)}
                      </td>
                      <td
                        className="align-middle situation"
                        style={{ display: "table-cell" }}
                        tabIndex="0"
                      >
                        {(
                          parseInt(item.assetAmount) /
                          10 ** item.decimals
                        ).toFixed(item.assetDecimalsToShow)}{" "}
                        {item?.symbol}
                        <br />
                        {(
                          parseInt(item.hyperTokenAmount) /
                          10 ** item.hyperTokenDecimals
                        ).toFixed(4)}{" "}
                        HYP
                      </td>
                      <td
                        className="align-middle"
                        style={{ display: "table-cell" }}
                        tabIndex="0"
                      >
                        {item.totalProfitLossAsset !== 0 && (
                          <>
                            {item.totalProfitLossAsset.toFixed(
                              item.assetDecimalsToShow
                            )}{" "}
                            {item?.symbol}
                            <br />
                          </>
                        )}
                        {item.totalProfitLossHyp !== 0 && (
                          <>{item.totalProfitLossHyp.toFixed(4)} HYP</>
                        )}
                      </td>
                      <td
                        className="align-middle"
                        style={{ display: "table-cell" }}
                        tabIndex="0"
                      >
                        {item?.symbol}:{" "}
                        {(parseInt(item.assetPrice) / 1e18).toFixed(4)} $
                        <br />
                        HYP:{" "}
                        {(parseInt(item.hyperTokenPrice) / 1e18).toFixed(4)} $
                      </td>
                      <td
                        className="align-middle"
                        style={{ display: "table-cell" }}
                        tabIndex="0"
                      >
                        {totalValue(item).toFixed(2)} $
                      </td>
                      <td
                        className="align-middle"
                        style={{ display: "table-cell" }}
                        tabIndex="0"
                      >
                        <Link
                          to={`/trading/${item.tradingPath}/${
                            item.cubeID || 0
                          }/${item.cubeINDEX || 0}/details`}
                          className="thm-btn comment-form__btn padding_select"
                          style={{
                            color: "var(--hypdex-base)",
                            backgroundColor: "var(--hypdex-white)",
                          }}
                        >
                          {t("Profit & Details")}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

//export default withTranslation()(withRouter(Account));
export default withTranslation()(Account);
